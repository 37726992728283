import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EmailField,
} from 'react-admin'

const AccountDeletionRequestsList = props =>{
  return (
    <List
      { ...props }
      title="Demandes de suppression de compte"
    >
      <Datagrid rowClick="show" bulkActionButtons={ false }>
        <TextField
          label="ID"
          sortable={ false }
          source="id"
        />
        <TextField
          label="Prénom"
          sortable={ false }
          source="user.firstname"
        />
        <TextField
          label="Nom"
          sortable={ false }
          source="user.lastname"
        />
        <EmailField
          label="Email"
          sortable={ false }
          source="user.email"
        />
        <EmailField
          label="Email du propriétaire de l'abonnement"
          sortable={ false }
          source="user.subscription.user.email"
        />
      </Datagrid>
    </List>
  )
}

export default AccountDeletionRequestsList

