import React from 'react'
import DescriptionIcon from '@mui/icons-material/Description'
import InsertChartIcon from '@mui/icons-material/InsertChart'
import SchoolIcon from '@mui/icons-material/School'
import LockOpenIcon from '@mui/icons-material/LockOpen'
import {
  TextField,
  Show,
  TabbedShowLayout,
  Tab,
  NumberField,
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  EmailField,
} from 'react-admin'
import UnlockQuiz from './UnlockQuiz'
import DpcAccess from './DpcAccess'
import { LogAsButton, ActiveUserEmailButton } from 'components/button'
import UserStatistics from './UserStatistics'
import { formatDate } from 'utils/date'

const UserShow = (props) => {

  return (
    <Show { ...props }>
      <TabbedShowLayout>
        <Tab
          icon={ <DescriptionIcon /> }
          label="Résumé"
        >
          <TextField
            label="Type"
            source='type'
          />
          <TextField
            label="Identifiant"
            source="id"
          />
          <TextField
            label="Nom d'utilisateur"
            source="username"
          />
          <TextField
            label="Nom"
            source="lastname"
          />
          <TextField
            label="Prénom"
            source="firstname"
          />
          <FunctionField
            label="Date de naissance"
            render={ record => record.birthdate
              ? formatDate(record.birthdate, false)
              : '' }
          />
          <EmailField
            label="E-mail"
            source="email"
          />
          <DateField
            label="Date de création"
            source="createdAt"
          />
          <FunctionField
            render={ record => {
              return record.activatedAt ? <FunctionField
                render={ record => record.activatedAt ? `Email validé le : ${new Date(record.activatedAt).toLocaleDateString()}` : null }
              /> : <ActiveUserEmailButton userId={ record.id } />
            } }
          />
          <TextField
            label="ID Stripe"
            source="stripeId"
          />
          <TextField
            label="Groupement"
            source="organization"
          />
          <TextField
            label="CIP Pharmacie"
            source="cip"
          />
          <TextField
            label="RPPS"
            source="rpps"
          />
          <FunctionField
            label="Justificatif RPPS"
            render={ record => record.rppsProof
              ? <a href={ `${record.rppsProof}` } target='_blank' rel="noreferrer">
                {record.rppsProof}
              </a>
              : null }
          />
          <TextField
            label="Profession"
            source="job"
          />
          <FunctionField
            label="Dernière connexion"
            render={ record => record.lastConnexion
              ? formatDate(record.lastConnexion)
              : '' }
          />
          <TextField
            label="Nombre de quiz validés depuis les derniers 365 jours (DPC)"
            source="quizsCompletedCount"
          />
          <ArrayField
            label="Abonnement(s)"
            source="subscriptions"
          >
            <Datagrid bulkActionButtons={ false }>
              <TextField
                label="Identifiant"
                source="id"
              />
              <FunctionField
                label="Propriétaire de l'abonnement"
                render={ record => `${record.user.firstname} ${record.user.lastname} (${record.user.email})` }
              />
              ;
              <TextField
                label="Plan"
                sortable={ false }
                source="plan.title"
              />
              <NumberField
                label="Quantité"
                sortable={ false }
                source="quantity"
              />
              <TextField
                label="Status"
                sortable={ false }
                source="status"
              />
              <DateField
                label="Date début"
                locales="fr-FR"
                sortable={ false }
                source="startDate"
              />
              <DateField
                label="Date fin"
                locales="fr-FR"
                sortable={ false }
                source="endDate"
              />
              <TextField
                label="Plateforme"
                sortable={ false }
                source="platform"
              />
              <TextField
                label="Url"
                sortable={ false }
                source="urlSource"
              />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab
          icon={ <InsertChartIcon /> }
          label="Statistiques"
        >
          <UserStatistics />
        </Tab>
        <Tab
          icon={ <LockOpenIcon /> }
          label="Déblocage Manuel quiz"
        >
          <UnlockQuiz />
        </Tab>
        <Tab
          icon={ <SchoolIcon /> }
          label="Accès DPC"
        >
          <DpcAccess />
        </Tab>
      </TabbedShowLayout>
      <LogAsButton />
    </Show>
  )
}

export default UserShow
