import { GET_LIST, GET_ONE, CREATE, UPDATE } from 'react-admin'
import { gql } from '@apollo/client'
import apolloClient from 'apolloClient'

const getList = (params) => apolloClient
  .query({
    query: gql`
        query ($page: Int, $perPage: Int) {
          allDailyQuestions(page: $page, perPage: $perPage) {
            id
            title
            answer
            createdAt
          }
          _allDailyQuestionsMeta {
            count
          }
        }
    `,
    variables: { ...params.pagination },
  })
  .then(({ data }) => ({
    data: data.allDailyQuestions,
    total: data._allDailyQuestionsMeta.count,
  }))

const getOne = ({ id }) => apolloClient
  .query({
    query: gql`
      query ($id: ID) {
        dailyQuestion(id: $id) {
          id
          title
          answer
          createdAt
        }
      }
    `,
    variables: {
      id,
    },
  })
  .then(({ data }) => ({
    data: data.dailyQuestion,
  }))

const create = params => apolloClient
  .mutate({
    mutation: gql`
      mutation ($title: String!, $answer: String!) {
        createDailyQuestion(title: $title, answer: $answer) {
          id
        }
      }
    `,
    variables: {
      title: params.data.title,
      answer: params.data.answer,
    },
  })
  .then(({ data }) => ({
    data: data.createDailyQuestion,
  }))

const update = params => apolloClient
  .mutate({
    mutation: gql`
        mutation ($id: ID!, $title: String!, $answer: String!) {
            updateDailyQuestion(id: $id, title: $title, answer: $answer) {
                id
            }
        }
    `,
    variables: {
      id: params.id,
      title: params.data.title,
      answer: params.data.answer,
    },
  })
  .then(({ data }) => ({
    data: data.updateDailyQuestion,
  }))

export default (type, params) => {
  switch (type) {
    case GET_LIST: return getList(params)
    case GET_ONE: return getOne(params)
    case CREATE: return create(params)
    case UPDATE: return update(params)
    default: return new Promise()
  }
}

