export const dateToISOStringWithoutExtension = date => {
  if (typeof date === 'string') return date

  return date.toISOString().slice(0,-5)+'Z'
}

export const formatDate = (dateString, withTime = true) => {
  const options = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  }

  if (withTime) {
    options.hour = '2-digit'
    options.minute = '2-digit'
    options.second = '2-digit'
  }

  return new Intl.DateTimeFormat('fr-FR', options).format(new Date(dateString))
}
