import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  Filter,
  TextInput,
} from 'react-admin'

const ThemeFilter = (props) => (
  <Filter { ...props }>
    <TextInput
      label="Rechercher"
      source="search"
      alwaysOn
    />
  </Filter>

)

const ListTheme = props =>
  <List
    { ...props }
    filters={ <ThemeFilter /> }
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <TextField
        label="Theme"
        sortable={ false }
        source="title"
      />
    </Datagrid>
  </List>

export default ListTheme
