import React from 'react'
import { List, Datagrid, DateField, RichTextField } from 'react-admin'

const DailyQuestionList = props =>
  <List
    { ...props }
  >
    <Datagrid rowClick="show" bulkActionButtons={ false }>
      <RichTextField
        label="Intitulé"
        source="title"
      />
      <DateField
        label="Publication"
        locales="fr-FR"
        source="createdAt"
        showTime
      />
    </Datagrid>
  </List>

export default DailyQuestionList

